import React, { useMemo } from 'react';
import Image from 'next/image';

import { capitalize } from '@zealy/utils';

import { cn } from '#utils/utils';

import { styles } from './RewardCard.styles';
import { type RewardCardProps } from './RewardCard.types';
import { RewardMethod } from './RewardMethod';
import { REWARD_IMAGE_SOURCES } from './Rewards.constants';

export const RewardCard = ({
  className,
  size,
  max,
  claimed,
  method,
  type,
  src,
  bgColor,
  overlay,
  value,
  roleName,
  onClick,
  settings,
  metadata,
  ...props
}: RewardCardProps) => {
  let image = src ?? REWARD_IMAGE_SOURCES.get(type);

  if (type === 'nft' && (props as any).imageUrl) {
    /* @ts-ignore */
    image = props.imageUrl;
  }

  const Component = size === 'md' ? 'button' : 'div';
  const shouldShowLabel = ['xp', 'role', 'other', 'token'].includes(type);

  const style = useMemo(
    () =>
      ({
        '--bg-color': bgColor ?? '#222222',
      } as React.CSSProperties),
    [bgColor],
  );

  const negativeReward = type === 'xp' && Number(value) < 0;

  const getLabel = () => {
    if (type === 'role') return roleName;
    if (type === 'token') return `${value} ${metadata?.symbol ?? ''}`;
    return value;
  };

  return (
    <Component
      type="button"
      className={cn(styles.root({ size }), className)}
      aria-disabled={props.disabled}
      onClick={onClick}
      style={style}
    >
      {overlay && <div className={styles.overlay({ size })} />}
      <RewardMethod size={size} method={method} max={max} value={claimed} />
      <div className={cn(styles.image({ size }), 'relative')}>
        {image && (
          <Image
            width={size === 'md' ? 294 : 100}
            height={size === 'md' ? 294 : 100}
            draggable={false}
            className={cn(styles.image({ size }), 'absolute inset-0', {
              'hue-rotate-60': negativeReward,
            })}
            src={image}
            alt=""
          />
        )}
        <label className="text-primary body-component-sm-bold text-center">
          {capitalize(type)}
        </label>
        {shouldShowLabel && value && (
          <div className={styles.labelContainer({ size })}>
            <label className={styles.label({ size, negativeReward })}>{getLabel()}</label>
          </div>
        )}
      </div>
    </Component>
  );
};
