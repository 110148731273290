import React from 'react';
import { cva } from 'class-variance-authority';

import type { QuestRecurrence } from '@zealy/utils';
import {
  CheckboxRecLine,
  CountFilled,
  DiscordBrand,
  DollarLine,
  Edit3Filled,
  FolderLine,
  GiftLine,
  Image2Filled,
  LoaderLine,
  RefreshCwLine,
  StarFilled,
  Timer2Line,
  TimerLine,
} from '@zealy/icons';

import { communitiesFlag } from '#context/FeatureFlags/communitiesFlag';

const enableTokenReward = communitiesFlag.getState().flags?.['ff_token_reward']?.enabled ?? false;

const STATUS = {
  label: 'status',
  value: 'published',
  icon: <LoaderLine />,
  options: [true, false] as const,
} as const;

export const RECURRENCE = {
  label: 'recurrence',
  value: 'recurrence',
  icon: <RefreshCwLine />,
  options: ['once', 'daily', 'weekly', 'monthly'] as QuestRecurrence[],
  showDescription: true,
} as const;

export const COOLDOWN = {
  label: 'cooldown',
  value: 'retryAfter',
  icon: <TimerLine />,
  options: ['0', '60', '300', '1800', '3600', '86400', '604800', '2592000', '-1'] as const,
  showDescription: true,
} as const;

export const CLAIM_LIMIT = {
  label: 'claim-limit',
  value: 'claimLimit',
  type: 'number',
  icon: <CountFilled />,
  showDescription: true,
  defaultValue: 0,
} as const;

const CONDITIONS = {
  label: 'conditions',
  value: 'conditions',
  icon: <CheckboxRecLine />,
  options: ['level', 'role', 'nft', 'quest', 'date', 'twitterFollowers'],
  showDescription: false,
} as const;

export const rewardIconStyles = cva('flex-shrink-0', {
  variants: {
    variant: {
      xp: 'icon-rewards-xp',
      nft: 'icon-rewards-nft',
      role: 'icon-rewards-role',
      other: 'icon-rewards-other',
      error: 'icon-onwarning-secondary',
      token: 'icon-rewards-token',
    },
  },
});

export const RewardIcons = {
  xp: StarFilled,
  role: DiscordBrand,
  nft: Image2Filled,
  other: Edit3Filled,
  token: DollarLine,
};

export const REWARDS = {
  label: 'rewards',
  value: 'rewards',
  icon: <GiftLine />,
  options: enableTokenReward
    ? (['xp', 'role', 'other', 'token'] as const)
    : (['xp', 'role', 'other'] as const),
  showDescription: false,
} as const;

const MODULE = {
  label: 'module',
  value: 'categoryId',
  icon: <FolderLine />,
  showDescription: false,
} as const;

const SPRINT = {
  label: 'sprint',
  value: 'sprintId',
  icon: <Timer2Line />,
  showDescription: false,
} as const;

export type SelectProperty = typeof RECURRENCE | typeof COOLDOWN;

export type ClaimLimitProperty = typeof CLAIM_LIMIT;

export type ConditionsProperty = typeof CONDITIONS;

export type RewardsProperty = typeof REWARDS;

export type ModuleProperty = typeof MODULE;

export type SprintProperty = typeof SPRINT;

export type Property =
  | SelectProperty
  | ClaimLimitProperty
  | ConditionsProperty
  | RewardsProperty
  | ModuleProperty
  | SprintProperty;

export const PROPERTIES = [
  RECURRENCE,
  COOLDOWN,
  CLAIM_LIMIT,
  CONDITIONS,
  REWARDS,
  MODULE,
  SPRINT,
] as const;

export const INLINE_PROPERTIES = [STATUS, RECURRENCE, COOLDOWN] as const;
